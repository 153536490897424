import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { LgTitle, SmTitle } from '../elements/Titles';
import { XsParagraph } from '../elements/Paragraphs';

const AccessibilityPolicy = () => (
  <Layout>
    <SEO title="Accessibility Policy" />
    <Container>
      <Row marginBottom="144">
        <Column>
          <LgTitle>Accessibility Policy</LgTitle>

          <SmTitle dark="true" marginBottom="6">
            Statement of organizational commitment
          </SmTitle>
          <XsParagraph>
            Wise Publishing is committed to ensuring equal access and participation for people with disabilities. We are
            committed to treating people with disabilities in a way that allows them to maintain their dignity and independence.
            We believe in integration and we are committed to meeting the needs of people with disabilities in a timely manner.
            We will do so by removing and preventing barriers to accessibility and meeting our accessibility requirements under
            the Accessibility for Ontarians with Disabilities Act and Ontario’s accessibility laws.
          </XsParagraph>

          <SmTitle dark="true" marginBottom="6">
            Training
          </SmTitle>
          <XsParagraph>
            We are committed to training staff and volunteers in Ontario’s accessibility laws and aspects of the Ontario Human
            Rights Code that relate to persons with disabilities.
          </XsParagraph>
          <XsParagraph>
            We will train our employees and volunteers on accessibility as it relates to their specific roles.
          </XsParagraph>

          <SmTitle dark="true" marginBottom="6">
            Information and communications
          </SmTitle>
          <XsParagraph>
            We will communicate with people with disabilities in ways that take into account their disability. When asked, we
            will provide information about our organization and its services, including public safety information, in accessible
            formats or with communication supports.
          </XsParagraph>
          <XsParagraph>
            We will also meet internationally recognized Web Content Accessibility Guidelines (WCAG) 2.0 Level AA website
            requirements in accordance with Ontario’s accessibility laws.
          </XsParagraph>

          <SmTitle dark="true" marginBottom="6">
            Employment
          </SmTitle>
          <XsParagraph>
            We will notify employees, potential hires and the public that accommodations can be made during recruitment and
            hiring.
          </XsParagraph>
          <XsParagraph>
            We will notify staff that supports are available for those with disabilities. We will put in place a process to
            develop individual accommodation plans for employees.
          </XsParagraph>
          <XsParagraph>
            Where needed, we will also provide customized emergency information to help an employee with a disability during
            an emergency.
          </XsParagraph>
          <XsParagraph>
            Our performance management, career development and redeployment processes will take into account the accessibility
            needs of all employees.
          </XsParagraph>

          <SmTitle dark="true" marginBottom="6">
            Changes to existing policies
          </SmTitle>
          <XsParagraph>
            We will modify or remove an existing policy if it does not respect and promote the dignity and independence of people
            with disabilities.
          </XsParagraph>

          <SmTitle dark="true" marginBottom="6">
            Accessibility Plan
          </SmTitle>
          <XsParagraph>
            Wise Publishing is committed to providing online access to personal financial information and resources to all adults
            accessing our sites. Wise will continue to meet internationally recognized Web Content Accessibility Guidelines (WCAG)
            2.1 Level AA website requirements in accordance with Ontario’s accessibility laws. Wise is committed to providing a
            barrier free work environment and is compliant with current AODA requirements.
          </XsParagraph>
          <XsParagraph>
            Wise will continue to take into account all AODA requirements when revising the work environment, platforms and
            equipment necessary for employees to complete their duties. There currently are no amendments required to comply with
            AODA requirements. Wise is committed to reviewing the requirements annually and incorporating accommodations and
            amendments to the above as part of an annual plan if and when required. We will modify or remove an existing policy if
            it does not respect and promote the dignity and independence of people with disabilities.
          </XsParagraph>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default AccessibilityPolicy;
